import {
  AUTH_CHANGE_PLAN,
  AUTH_FAILED,
  AUTH_LOGIN,
  AUTH_USER,
} from '../actions/_actionTypes'

import { asyncError, asyncRequest, setGenericError } from './'
import axios from '../../settings/axios'
import { history } from '../../routers/AppRouter'
import * as routes from '../../settings/_routesSettings'
import { apiEndpoints } from '../../settings/_apiSettings'
import { setAuthToken, deleteAuthToken, getAuthToken } from 'utils/authHelper'
import * as Sentry from '@sentry/browser'
import { TVTVADMN } from 'settings/_profileSettings'
import { openReplayTracker } from 'index'

/* User login action */
export const authLogin = (loginData, redirectTo, signupSuccess) => async dispatch => {
  const fetchUrl = apiEndpoints.AUTH
  const fetchData = { ...loginData }
  dispatch(asyncRequest())

  try {
    const response = await axios.post(fetchUrl, fetchData)

    if (response.status === 'OK') {
      const userToken = response.data.jwt
      const payload = { userToken }

      await dispatch({
        type: AUTH_LOGIN,
        payload,
      })

      await dispatch(authUser(userToken))
    } else {
      let authFailedPayload = response.messages[0].message
      dispatch(authFailed(authFailedPayload))
    }
  } catch (err) {
    dispatch(setGenericError('Não foi possível se autenticar. Entre em contato através do nosso chat para completar seu cadastro.'))
  }
}

/* User logout action */
export const authLogout = () => async (dispatch) => {
  const fetchUrl = apiEndpoints.LOGOUT
  const currentToken = getAuthToken()

  Sentry.configureScope(scope => scope.setUser(null))
  await axios.post(fetchUrl, { jwt: currentToken })

  deleteAuthToken()
  history.push(routes.LOGIN)
}

/** User authentication action */
export const authUser = (userToken) => async dispatch => {
  let fetchUrl = apiEndpoints.ME
  dispatch(asyncRequest())
  setAuthToken(userToken)

  try {
    const response = await axios.get(fetchUrl)

    if (response.status === 'OK') {
      const userData = response.data
      const activeProfile = userData.session.profile_active
      const profileSelected = userData.person_profile.find(profile => profile.profile_code === activeProfile)
      const planSelected = profileSelected.plans.find(plan => plan.default === true)
      const profilePermissions = profileSelected.permissions

      const payload = {
        planSelected,
        profilePermissions,
        profileSelected,
        userData,
        userToken,
      }

      // Informa ao Sentry dados do usuário logado
      Sentry.setUser({
        id: userData.session.sub,
        username: userData.session.username,
      })

      // Informa ao OpenReplay o e-mail do usuário logado
      openReplayTracker.setUserID(userData.session.username)

      dispatch({ type: AUTH_USER, payload })

      if (activeProfile === TVTVADMN) {
        history.push(routes.DASHBOARD)
      } else {
        dispatch(authSwap('TVTVADMN'))
      }

    } else {
      dispatch(asyncError(response.messages))
    }
  } catch (err) {
    dispatch(asyncError(err))
  }
}

/* User authentication action */
export const authSwap = (profile) => async dispatch => {
  let fetchUrl = apiEndpoints.ME
  let postData = { 'profile_code': profile }
  dispatch(asyncRequest())

  try {
    const response = await axios.post(fetchUrl, postData)

    if (response.status === 'OK') {
      dispatch(authUser(response.data.jwt))
    }
  } catch (err) {
    dispatch(authLogout())
    dispatch(setGenericError('Acesso negado.'))
  }
}

/* User authentication action */
export const authChangePlan = (plan) => dispatch => {
  const payload = { planSelected: plan }

  dispatch({
    type: AUTH_CHANGE_PLAN,
    payload,
  })

}

/* Authentication failed */
export const authFailed = (payload) => {
  if (!payload) payload = ''

  return {
    type: AUTH_FAILED,
    payload,
  }
}
