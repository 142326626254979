import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Tracker from '@openreplay/tracker'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { configureStore } from './redux/cofigureStore'
import { initializeIntercom } from './utils/intercomHandlers'
import { sanitizer } from 'utils/sanitizer'
import { DimensionsProvider, ThemeProvider } from '@telavita-core/react-design-kit'

import 'normalize-css/normalize.css'
import 'react-phone-number-input/style.css'
import './static/styles/styles.sass'

initializeIntercom(process.env.REACT_APP_INTERCOM_ID)

if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  })
}

export const store = configureStore()
const rootEl = document.getElementById('root')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 60 * 1000 * 60, // 1 hora
    }
  }
})

export const openReplayTracker = new Tracker({
  projectKey: process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY,
  ingestPoint: 'https://openreplay.telavita.com.br/ingest',
  __DISABLE_SECURE_MODE: process.env.NODE_ENV === 'development',
  __debug__: process.env.NODE_ENV === 'development',
  network: {
    capturePayload: true,
    sanitizer: sanitizer,
  },
})

openReplayTracker.start()

ReactDOM.render(
  <ThemeProvider>
    <DimensionsProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
            useEnterprise={true}
          >  
            <App />
          </GoogleReCaptchaProvider>
        </QueryClientProvider>
      </Provider>
    </DimensionsProvider>
  </ThemeProvider>
  ,
  rootEl
)

serviceWorker.unregister()
