import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Select } from '@telavita-core/react-design-kit'

import ModalGeneric from '../ModalGeneric'
import viewportHoc from '../../../../hoc/viewportHoc'
import Dropdown from '../../../../components/Dropdown'
import { IntegerInput } from 'components/TextInput/Inputs'
import { Typography, CoreIcons, TextInput, Spinner } from 'components'

import { removeMaskInputValue } from '../../../../utils/textTransformations'
import axios from '../../../../settings/axios'
import InputValidation from '../../../../utils/InputValidation'

import * as actions from '../../../../redux/actions'
import { hideModal } from '../../../../redux/actions'
import { apiEndpoints } from '../../../../settings/_apiSettings'
import { planGroupsSettings } from 'settings/_planGroupSettings'
import { COUNTRY_STATES } from 'settings/_countryStatesSettings'

const steps = {
  SELECT_PLAN: 'SELECT_PLAN',
  CARD_NUMBER: 'CARD_NUMBER',
  PATIENT_INFO: 'PATIENT_INFO',
}

const OUTPATIENT_SCHEDULE_PSYCHOLOGY = 'OUTPATIENT_SCHEDULE_PSYCHOLOGY'
const SCHEDULE_PSYCHOLOGY = 'SCHEDULE_PSYCHOLOGY'
const EVALUATION = 'EVALUATION'
const THERAPY = 'THERAPY'

const solicitationTypes = () => [
  { code: OUTPATIENT_SCHEDULE_PSYCHOLOGY, name: 'Consulta de avaliação (Bradesco) - 50001221' },
  { code: SCHEDULE_PSYCHOLOGY, name: 'Consulta de terapia - 94000470' },
  { code: EVALUATION, name: 'Consulta de avaliação - 50000462' },
  { code: THERAPY, name: 'Consulta de terapia - 50000470' },
]

class ModalNewMedicalRequest extends Component {
  state = {
    cardNumber: {
      value: '',
      isValid: false,
      hasError: false,
      message: '',
    },
    currentStep: steps.SELECT_PLAN,
    hasMedicalRequest: false,
    medicalRequest: {
      placeholder: null,
      value: null,
      isValid: true,
      message: null,
    },
    isLoading: false,
    success: false,
    error: false,
    selectedPlanGroup: '',
    userData: {
      planName: '',
      planCode: '',
      personName: '',
    },
    diseaseInfo: {
      value: '',
      isValid: false,
      message: '',
    },
    doctorUf: { value: '' },
    doctorCrm: {
      value: '',
      isValid: true,
    },
    solicitation: '',
  };

  handleSelectPlanGroup = code => {
    let filteredPlanGroup = this.props.planGroups.find(currentItem => currentItem.code === code)
    this.setState({
      selectedPlanGroup: filteredPlanGroup.code,
      currentStep: steps.CARD_NUMBER,
      feedback: null,
    })
  };

  componentDidMount() {
    this.props.onGetGroupPlans()
  }

  handleCloseButton = () => {
    this.clearMedicalRequestReducer()
    this.props.onHideModal()
  }

  solicitationTypeOptions = () => {
    const planGroupCode = this.props?.planGroups.find(pGroup => pGroup.code === this.state.selectedPlanGroup)?.code
    const planGroupProcedures = this.props?.planGroups.find(pGroup => pGroup.code === this.state.selectedPlanGroup)?.procedures

    if (!planGroupProcedures || !planGroupCode) return []

    return solicitationTypes()?.reduce((acc, curr) => {
      if (planGroupProcedures.includes(curr.code)) {
        acc.push(curr)
      }

      return acc
    }, [])
  }

  checkCardNumber = () => {
    const fetchUrl = apiEndpoints.PERSON_PROFILE_BY_CARD(
      this.state.cardNumber.value,
      this.state.selectedPlanGroup
    )
    axios.get(fetchUrl)
      .then(response => {
        if (response.status === 'OK') {
          this.setState({
            userData: {
              personName: response?.data?.person_profile?.person?.person_general?.full_name || '',
              planName: response?.data?.plan?.name || '',
              planCode: response?.data?.plan?.code || '',
            },
          })
          this.changeStep(steps.PATIENT_INFO)
        }
      })
      .catch(err => {
        this.setState({
          cardNumber: {
            ...this.state.cardNumber,
            isValid: false,
            hasError: true,
            message: err?.response?.data?.messages[0]?.message || '',
          },
          userData: {
            planName: '',
            planCode: '',
            personName: '',
          },
        })
      }).finally(() => {
        this.setState({
          medicalRequest: {
            placeholder: null,
            value: null,
            isValid: true,
            message: null,
          },
          diseaseInfo: {
            value: '',
            isValid: false,
            message: '',
          },
          doctorUf: { value: '' },
          doctorCrm: {
            value: '',
            isValid: true,
          },
        })
      })
  }

  createNewMedicalRequest = async () => {
    const settings = planGroupsSettings[this.state.selectedPlanGroup]
    
    this.setState({
      isLoading: true,
      feedback: null,
    })

    const treatment_validity = {
      our_evaluation: true,
      amb_code: this.state.solicitation,
      ...this.state.medicalRequest.value && {
        medical_request: this.state.medicalRequest.value,
        our_evaluation: false
      },
      ...settings?.diseaseInfo?.cid && {
        cid: this.state.diseaseInfo.value,
      },
      ...(settings?.doctorInfo?.crm && settings?.doctorInfo?.uf) && {
        professional_crm_code: this.state.doctorCrm.value,
        professional_crm_uf: this.state.doctorUf.value,
      }
    }

    await this.props.onCreateMedicalRequest({
      profile_role: 'PACI',
      plan_code: this.state.userData.planCode,
      card_number: this.state.cardNumber.value,
      treatment_validity,
    })

    if (this.props.createSuccess) {
      await this.setState({ isLoading: false, success: true })
    }

    if (this.props.createError) {
      await this.setState({ isLoading: false, error: true })
    }

    if (this.props.createFeedbackMessage) {
      await this.setState({ isLoading: false, feedback: this.props.createFeedbackMessage })
    }
  }

  changeStep = (newStep) => {
    this.setState({
      currentStep: newStep,
      feedback: null,
    })
  }

  clearMedicalRequestReducer = () => {
    this.props.onClearMedicalRequest()
  }

  handleCardNumber = (e, mask) => {
    let cleanedValue = e.target.value.split('.').join('')
    let inputValue = removeMaskInputValue(cleanedValue)
    let minLength = mask.match(/1/g)?.length || 0
    let validation = inputValue.length === minLength
    this.setState({
      cardNumber: {
        ...this.state.cardNumber,
        value: inputValue,
        isValid: validation,
        hasError: false,
      },
    })
    this.changeStep(steps.CARD_NUMBER)
  }

  handleMedicRequestRadio = (value) => {
    this.setState({
      hasMedicalRequest: value,
      medicalRequest: {
        value: null,
        isValid: true,
        message: null,
      },
    })
  }

  handleMedicRequestFile = async (e) => {
    let getFile = e.target.files
    let imgName = getFile[0] ? getFile[0].name : ''

    let validation = new InputValidation(getFile, 'imageOrPdf')
    let imageIsValid = await validation.validate()

    this.setState({
      medicalRequest: {
        placeholder: imgName,
        value: imageIsValid.encodedFile,
        isValid: imageIsValid.success,
        message: imageIsValid.message,
      },
    })
  }

  handleCidValue = (e) => {
    let value = e.target.value
    let validation = new InputValidation(value, 'cid').validate()

    this.setState({
      diseaseInfo: {
        value,
        isValid: validation.success,
        message: validation.message,
      },
    })
  }

  isDisabledButton = () => {
    const {
      currentStep,
      selectedPlanGroup,
      cardNumber,
      medicalRequest,
      doctorCrm,
      doctorUf,
      diseaseInfo,
      solicitation,
    } = this.state

    const settings = planGroupsSettings[selectedPlanGroup]
    let disabled = true

    if (currentStep === steps.SELECT_PLAN) {
      if (selectedPlanGroup !== '') disabled = false
    }

    if (currentStep === steps.CARD_NUMBER) {
      if (cardNumber.isValid) disabled = false
    }

    if (currentStep === steps.PATIENT_INFO) {
      disabled = solicitation === ''

      if (solicitation === THERAPY) {
        disabled = disabled || !(medicalRequest.isValid && medicalRequest.value)

        if (settings?.diseaseInfo?.cid) {
          disabled = disabled || !(diseaseInfo.isValid && diseaseInfo.value)
        }

        if (settings?.doctorInfo?.crm) {
          disabled = disabled || !(doctorCrm.isValid && doctorCrm.value)
        }

        if (settings?.doctorInfo?.uf) {
          disabled = disabled || !doctorUf.value
        }
      }

    }

    return disabled
  }

  submitAction = () => {
    const { currentStep, success, error } = this.state
    if (success || error) {
      this.handleCloseButton()
    } else {
      if (currentStep === steps.SELECT_PLAN) {
        this.changeStep(steps.CARD_NUMBER)
      }
      if (currentStep === steps.CARD_NUMBER) {
        this.checkCardNumber()
      }
      if (currentStep === steps.PATIENT_INFO) {
        this.createNewMedicalRequest()
      }
    }
  }

  getModalTitle = () => {
    const { success, error } = this.state
    return success ? 'Solicitação efetuada\ncom sucesso!' : (error ? 'Solicitação Reprovada' : 'Nova solicitação')
  }

  getBtnText = () => {
    const { success, error, currentStep } = this.state
    return success || error ? 'FECHAR' : (currentStep === steps.PATIENT_INFO) ? 'FAZER SOLICITAÇÃO' : 'PRÓXIMO'
  }

  availablePlanGroups = () => {
    const planGroupsWithLegacyConfig = this.props.planGroups.filter(pg => {
      return (
        planGroupsSettings[pg.code] &&
        planGroupsSettings[pg.code].medicalRequest &&
        planGroupsSettings[pg.code].medicalRequest.enabled
      )
    })

    const planGroupsWithRenderingConfig = this.props.planGroups.filter(planGroup => {
      return planGroup.rendering_config?.identifier_mask
    })
    
    const concatPlanGroups = planGroupsWithLegacyConfig.concat(planGroupsWithRenderingConfig)
    const sortedPlanGroups = concatPlanGroups.sort((a, b) => a.name.localeCompare(b.name))

    return sortedPlanGroups
  }

  handleDoctorUF = (code) => {
    this.setState({
      doctorUf: {
        ...this.state.doctorUf,
        value: code,
      },
    })
  }

  handleSolicitationType = (code) => {
    this.setState({
      solicitation: code,
    })
  }

  handleDoctorCrm = (value) => {
    let validation = new InputValidation(value, 'integer').validate()
    this.setState({
      doctorCrm: {
        ...this.state.doctorCrm,
        value: value,
        isValid: validation.success,
        message: validation.message,
      },
    })
  }

  formatPlanGroupOptions = (options) =>
    options.map((currentItem) => ({
      id: currentItem.code,
      label: currentItem.name
    }))

  getPlanGroupIdentifierConfig(hasSelectedPlanGroupRenderingConfig, selectedPlanGroupData, legacyPlanGroupSettings) {
    return hasSelectedPlanGroupRenderingConfig
      ? {
        mask: selectedPlanGroupData.rendering_config.identifier_mask,
        placeholder: selectedPlanGroupData.rendering_config.identifier_placeholder,
        medicalRequestEnabled: selectedPlanGroupData.rendering_config.medical_request_enabled,
        medicalRequestRequired: selectedPlanGroupData.rendering_config.medical_request_required,
        medicalRequestPlaceholder: selectedPlanGroupData.rendering_config.medical_request_placeholder
      } : {
        mask: legacyPlanGroupSettings?.identifier.mask,
        placeholder: legacyPlanGroupSettings?.identifier.placeholder,
        medicalRequestEnabled: legacyPlanGroupSettings?.medicalRequest.enabled,
        medicalRequestRequired: legacyPlanGroupSettings?.medicalRequest.required,
        medicalRequestPlaceholder: legacyPlanGroupSettings?.medicalRequest.placeholder,
      }
  }

  render() {
    const { btnId, onSuccessAction } = this.props
    const { isLoading, success, error, feedback, selectedPlanGroup } = this.state
    const availablePlanGroups = this.availablePlanGroups()
    const legacyPlanGroupSettings = planGroupsSettings[selectedPlanGroup]
    const selectedPlanGroupData = availablePlanGroups.find(pg => pg.code === selectedPlanGroup)
    const hasAvailablePlanGroups = availablePlanGroups.length > 0
    const hasSelectedPlanGroupRenderingConfig = selectedPlanGroupData?.rendering_config?.identifier_mask
    const placeholderOption = hasAvailablePlanGroups ? 'Planos e empresas disponíveis' : 'Carregando lista...'

    const planGroupIdentifier = this.getPlanGroupIdentifierConfig(hasSelectedPlanGroupRenderingConfig, selectedPlanGroupData, legacyPlanGroupSettings)
  
    return (
      <ModalGeneric
        modalTitle={this.getModalTitle()}
        modalSubtitle={
          <Fragment>
            <Typography align="center" color="black">Uma nova solicitação será criada</Typography>
            <Typography align="center" color="black">{'com o status "aguardando validação".'}</Typography>
          </Fragment>
        }
        closeModal={() => this.handleCloseButton()}
        btnId={btnId}
        btnText={this.getBtnText()}
        btnOnClick={() => this.submitAction()}
        btnDisabled={this.isDisabledButton()}
        hasButton={isLoading ? false : true}
        feedbackType={success ? 'success' : 'error'}
        feedbackMessage={success ? 'Solicitação efetuada com sucesso!' : 'Solicitação Reprovada'}
        feedbackShow={success || error}
        onSuccessClose={onSuccessAction}
        extraClassName>
        {
          !success && !error &&
          <>
            <div className="Container__dropdown">
              <Select
                label=''
                placeholder={placeholderOption}
                options={this.formatPlanGroupOptions(availablePlanGroups)}
                onSelect={(value) => this.handleSelectPlanGroup(value?.id)}
                selected={selectedPlanGroupData && {
                  id: selectedPlanGroupData?.code,
                  label: selectedPlanGroupData?.name
                }}
                position='fixed'
              />
            </div>

            {(this.state.currentStep === steps.CARD_NUMBER ||
              this.state.currentStep === steps.PATIENT_INFO) &&
              <div className="Container__textbox">
                <TextInput
                  placeholder={planGroupIdentifier.placeholder}
                  mask={planGroupIdentifier.mask}
                  required={true}
                  name="card_number"
                  onInputChange={(e) => this.handleCardNumber(e, planGroupIdentifier.mask)}
                  onFocusOut={(e) => this.handleCardNumber}
                  messageError={this.state.cardNumber.message}
                  hasError={this.state.cardNumber.hasError}
                  value={this.state.cardNumber.value}
                />
              </div>
            }

            {this.state.currentStep === steps.PATIENT_INFO &&
              <div className="Container__textbox">
                <TextInput
                  placeholder="Nome do paciente"
                  name="person_name"
                  disabled={true}
                  readOnly={true}
                  value={this.state.userData.personName}
                />
                <TextInput
                  placeholder="Nome do plano"
                  name="plan_name"
                  disabled={true}
                  readOnly={true}
                  value={this.state.userData.planName}
                />
              </div>
            }

            {this.state.currentStep === steps.PATIENT_INFO &&
              <div className="Container__StepFile">
                <div className="Register__InfoGroup__Forms__Item">
                  <Dropdown
                    name="solicitation"
                    options={this.solicitationTypeOptions()}
                    onSelect={(code) => this.handleSolicitationType(code)}
                    placeholder="Selecione o tipo de procedimento"
                    dropDownOverflow
                    dropDownItensToShow="3"
                  />
                </div>

                <div className="Container__StepFile__Forms__Item">
                  <div className="Container__StepFile__Forms--image-wrapper">
                    <TextInput
                      addIcon={true}
                      placeholder={planGroupIdentifier.medicalRequestPlaceholder}
                      name="medic_request"
                      hasError={!this.state.medicalRequest.isValid}
                      iconProps={{ fill: '#898989', height: '20px', name: 'UploadFile', width: '20px' }}
                      inputHeight='4.8rem'
                      messageError={this.state.medicalRequest.message}
                      value={this.state.medicalRequest.placeholder}
                      readOnly={true}
                    />
                    <input id="hiddenInput-file" type='file' onChange={(e) => this.handleMedicRequestFile(e)} />
                  </div>
                </div>

                {
                  legacyPlanGroupSettings?.doctorInfo?.crm &&
                  <div className="Register__InfoGroup__Forms__Item">
                    <IntegerInput
                      id="crm_number"
                      placeholder="Número do CRM"
                      onComponentUpdate={(data) => this.handleDoctorCrm(data.value)}
                      value={this.state.doctorCrm.value}
                    />
                  </div>
                }

                {
                  legacyPlanGroupSettings?.doctorInfo?.uf &&
                  <Fragment>
                    <div className="Register__InfoGroup__Forms__Item">
                      <Dropdown
                        name="plan"
                        options={COUNTRY_STATES}
                        onSelect={(code) => this.handleDoctorUF(code)}
                        placeholder="UF do CRM (2 letras)"
                        dropDownOverflow
                        dropDownItensToShow="3"
                      />
                    </div>
                  </Fragment>
                }

                {
                  legacyPlanGroupSettings?.diseaseInfo?.cid &&
                  <div className="Container__textbox">
                    <TextInput
                      id="cid_value"
                      maxLength="10"
                      placeholder={legacyPlanGroupSettings.diseaseInfo.placeholder}
                      onInputChange={(e) => this.handleCidValue(e)}
                    />
                  </div>
                }

                {
                  legacyPlanGroupSettings?.medicalRequest?.solicitationText &&
                  <div className="Register__InfoGroup__Forms__Item__HelperText">
                    {legacyPlanGroupSettings.medicalRequest.solicitationText}
                  </div>
                }

                {
                  feedback && (
                    <div className="Container__StepFile__Error">
                      <div className="Container__StepFile__Error__Icon">
                        <CoreIcons
                          fill="#eb384d"
                          name="Info"
                          width="35px"
                          viewBox="00 00 25 25"
                        />
                      </div>
                      <div className="Container__StepFile__Error__Text">
                        <Typography color="false">
                          {feedback}
                        </Typography>
                      </div>
                    </div>
                  )
                }
              </div>
            }

            {isLoading && <Spinner height="65px" />}

          </>
        }
      </ModalGeneric>
    )
  }
}

ModalNewMedicalRequest.propTypes = {}

const mapStateToProps = state => {
  return {
    planGroups: state.plans.allPlansGroups.plan_groups || [],
    userData: state.auth.userData,
    medicalRequestSuccess: state.medicalRequests.success,
    createSuccess: state.medicalRequests.createSuccess,
    createError: state.medicalRequests.createError,
    createFeedbackMessage: state.medicalRequests.createFeedbackMessage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClearMedicalRequest: () => dispatch(actions.clearMedicalRequest()),
    onGetGroupPlans: () => dispatch(actions.getAllPlansGroups()),
    onCreateMedicalRequest: (params) => dispatch(actions.createMedicalRequest(params)),
    onHideModal: () => dispatch(hideModal()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(viewportHoc(ModalNewMedicalRequest))
