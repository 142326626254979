import { apiEndpoints } from 'settings/_apiSettings'

interface RequestData {
  body: BodyInit | null | undefined
  headers: Record<string, string>
}

interface ResponseData {
  body: string | unknown | null
  headers: Record<string, string>
}

interface RequestResponseData {
  readonly status: number
  readonly method: string
  url: string
  request: RequestData
  response: ResponseData
}

const baseUrl = process.env.REACT_APP_API_URL
const authUrl = `${baseUrl}${apiEndpoints.AUTH}`

export function sanitizer(data: RequestResponseData): RequestResponseData {
  if (data.url === authUrl && typeof data.request.body === 'string') {
    try {
      const body = JSON.parse(data.request.body)
      if (body.password) {
        body.password = '<SANITIZED>'
        data.request.body = JSON.stringify(body)
      }
    // eslint-disable-next-line no-empty
    } catch {}
  }

  try {
    if (data.status < 400 && (data.response.body as any).data.jwt) {
      (data.response.body as any).data.jwt = '<TOKEN>'
    }
    // eslint-disable-next-line no-empty
  } catch {}

  return data
}